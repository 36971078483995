import React from "react"
import { css } from "@emotion/react"
import styled from "@emotion/styled"

import { breakpoints, colours, MetaText } from "design-kit"

export type YearType =
  | 2014
  | 2015
  | 2016
  | 2017
  | 2018
  | 2019
  | 2020
  | 2021
  | 2022
  | 2023

interface LineProps {
  width: string
  hide?: boolean
  dash?: boolean
}
const Line = styled.div<LineProps>`
  ${props =>
    props.hide
      ? "border-top: none;"
      : props.dash
      ? `border-top: 2px dashed ${colours.offBlack}`
      : `border-top: 2px solid ${colours.offBlack}`};
  width: ${props => props.width};
  ${props => props.dash && "margin-right: 4px;"}
`

interface DotProps {
  hide: boolean
  left: string
}
const Dot = styled.div<DotProps>`
  ${props => props.hide && "display: none;"}
  position: absolute;
  width: 16px;
  height: 16px;
  background-color: ${colours.offBlack};
  border-radius: 50%;
  top: -7px;
  ${props => `left: calc(${props.left} - 8px);`}
`

const Year = styled(MetaText)<{ left: string }>`
  display: inline-block;
  position: absolute;
  bottom: -50px;
  ${props => `left: calc(${props.left} - 17px);`}
`

const BigDot = (
  <React.Fragment>
    <div
      css={css`
        position: absolute;
        width: 48px;
        height: 48px;
        background-color: rgba(21, 21, 21, 0.4);
        border-radius: 50%;
        top: -23px;
        left: calc(50% - 24px);
      `}
    />
    <div
      css={css`
        position: absolute;
        width: 32px;
        height: 32px;
        background-color: ${colours.offBlack};
        border-radius: 50%;
        top: -15px;
        left: calc(50% - 16px);
      `}
    />
  </React.Fragment>
)

interface Props {
  i: number
  journeyLength: number
  previousPreviousYear?: YearType
  previousYear?: YearType
  year?: YearType
  nextYear?: YearType
  nextNextYear?: YearType
}
const Timeline: React.FunctionComponent<Props> = ({
  i,
  journeyLength,
  previousPreviousYear,
  previousYear,
  year,
  nextYear,
  nextNextYear,
}) => {
  const hideLine1 = i - 2 <= 0
  const hideLine2 = i - 1 <= 0
  const hideLine3 = i === 0
  const dashLine4 = i === journeyLength - 1
  const dashLine5 = i + 1 >= journeyLength - 1
  const dashLine6 = i + 2 >= journeyLength - 1

  const hideDot1 = i <= 1
  const hideDot2 = i <= 0
  const hideDot3 = i >= journeyLength - 1
  const hideDot4 = i + 1 >= journeyLength - 1

  return (
    <div
      aria-hidden
      css={css`
        display: none;
        ${breakpoints.desktop`
            display: flex;
            position: relative;
            margin-top: 40px;
        `}
      `}
    >
      <Line width="10%" hide={hideLine1} />
      <Line width="20%" hide={hideLine2} />
      <Line width="20%" hide={hideLine3} />
      <Line width="20%" dash={dashLine4} />
      <Line width="20%" dash={dashLine5} />
      <Line width="10%" dash={dashLine6} />

      <Dot left="10%" hide={hideDot1} />
      <Dot left="30%" hide={hideDot2} />
      {BigDot}
      <Dot left="70%" hide={hideDot3} />
      <Dot left="90%" hide={hideDot4} />

      <Year left="10%">{previousPreviousYear}</Year>
      <Year left="30%">{previousYear}</Year>
      <Year left="50%">{year}</Year>
      <Year left="70%">{nextYear}</Year>
      <Year left="90%">{nextNextYear}</Year>
    </div>
  )
}

export default Timeline
