import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/react"

import {
  breakpoints,
  Headline,
  horizontal,
  iconSize,
  MetaText,
  OneLogo,
  PartyIcon,
  PlusLogo,
  TertiaryLink,
  vertical,
} from "design-kit"

import trustpilot from "../assets/ourJourney/trustpilot.png"
import bba from "../assets/ourJourney/bba-2020.png"
import bbaWebp from "../assets/ourJourney/bba-2020.webp"

import { YearType } from "./Timeline"

const Year = styled(MetaText)`
  margin-bottom: ${vertical.xs};
`

const Logo = styled.div`
  margin-bottom: ${vertical.xs};
`

const ReadMoreLink = styled(TertiaryLink)`
  margin-top: auto;
`

const one = (
  <React.Fragment>
    <Year>2014</Year>
    <Headline>
      Dan tries to buy his first home, but it nearly falls through. It sparks an
      idea.
    </Headline>
  </React.Fragment>
)
const two = (
  <React.Fragment>
    <Year>2015</Year>
    <Headline>
      Habito moves out of Dan’s kitchen and into a coworking space.
    </Headline>
  </React.Fragment>
)
const three = (
  <React.Fragment>
    <Year>2015</Year>
    <Headline>
      The first version of Habito launches to friends and family.
    </Headline>
  </React.Fragment>
)
const four = (
  <React.Fragment>
    <Year>2016</Year>
    <Headline>
      We get our brokerage licence from the Financial Conduct Authority (FCA).
    </Headline>
  </React.Fragment>
)
const five = (
  <React.Fragment>
    <Year>2016</Year>
    <div
      css={css`
        width: 100%;
        display: flex;
        justify-content: space-between;

        ${breakpoints.tablet`
          justify-content: unset;
        `}
      `}
    >
      <Headline
        css={css`
          margin-right: ${horizontal.s};
        `}
      >
        12 April: we sort our first mortgage.
      </Headline>
      <span
        css={css`
          margin-top: -${vertical.xxs};
        `}
      >
        <PartyIcon width={iconSize.l} height={iconSize.l} />
      </span>
    </div>
  </React.Fragment>
)
const six = (
  <React.Fragment>
    <Year>2017</Year>
    <Headline>
      We move to The Loom building in Whitechapel, the current Habito HQ.
    </Headline>
  </React.Fragment>
)
const seven = (
  <React.Fragment>
    <Year>2017</Year>
    <Headline>We launch our first ever ad campaign.</Headline>
  </React.Fragment>
)
const eight = (
  <React.Fragment>
    <Year>2017</Year>
    <Headline>The team grows to over 50.</Headline>
  </React.Fragment>
)
const nine = (
  <React.Fragment>
    <Year>2017</Year>
    <Headline>We’ve sorted £361 million worth of mortgages.</Headline>
  </React.Fragment>
)
const ten = (
  <React.Fragment>
    <Year>2018</Year>
    <Headline>We’ve sorted £1 billion worth of mortgages.</Headline>
  </React.Fragment>
)
const eleven = (
  <React.Fragment>
    <Year>2018</Year>
    <Headline>Fairer Finance certifies our website as “jargon-free”.</Headline>
  </React.Fragment>
)
const twelve = (
  <React.Fragment>
    <Year>2019</Year>
    <Headline>
      We partner with Starling, Money Saving Expert, Property Investment
      Project, Finder, Money to the Masses and The Move Market.
    </Headline>
  </React.Fragment>
)
const thirteen = (
  <React.Fragment>
    <Year>2019</Year>
    <Headline>We’ve sorted £2 billion worth of mortgages.</Headline>
  </React.Fragment>
)
const fourteen = (
  <React.Fragment>
    <Year>2019</Year>
    <Headline>
      We launch our own mortgages! We’re the first company to be both a broker
      and a lender.
    </Headline>
    <ReadMoreLink
      text="Read more"
      href="/our-btl-range"
      descriptiveText="More about Habito’s buy-to-let mortgages"
      width="content-width"
    />
  </React.Fragment>
)
const fifteen = (
  <React.Fragment>
    <Year>2020</Year>
    <Logo>
      <PlusLogo />
    </Logo>
    <Headline>We launch Habito Plus, our complete homebuying service.</Headline>
    <ReadMoreLink
      text="Read more"
      href="/home-buying"
      descriptiveText="More about our complete home-buying service"
      width="content-width"
    />
  </React.Fragment>
)
const sixteen = (
  <React.Fragment>
    <Year>2020</Year>
    <Logo>
      <img
        src={trustpilot}
        alt="Trustpilot: 5 stars"
        css={css`
          height: 24px;
        `}
      />
    </Logo>
    <Headline>We get our 5,000th Trustpilot review.</Headline>
    <ReadMoreLink
      text="Read more customer stories"
      href="https://uk.trustpilot.com/review/habito.com"
      width="content-width"
    />
  </React.Fragment>
)
const seventeen = (
  <React.Fragment>
    <Year>2020</Year>
    <Headline>
      We’re voted Best Mortgage Broker at the British Bank Awards.
    </Headline>
    <picture>
      <source srcSet={bbaWebp} type="image/webp" />
      <img
        src={bba}
        alt=""
        css={css`
          height: 64px;
          margin-top: ${vertical.xs};
        `}
      />
    </picture>
  </React.Fragment>
)
const eighteen = (
  <React.Fragment>
    <Year>2020</Year>
    <Headline>We’ve sorted £4 billion worth of mortgages.</Headline>
  </React.Fragment>
)
const nineteen = (
  <React.Fragment>
    <Year>2021</Year>
    <Logo>
      <OneLogo />
    </Logo>
    <Headline>
      We launch Habito One, the UK’s only fixed for life mortgage.
    </Headline>
  </React.Fragment>
)
const twenty = (
  <React.Fragment>
    <Year>2022 - February</Year>
    <Headline>We’ve sorted more than £8 billion worth of mortgages.</Headline>
  </React.Fragment>
)
const twentyone = (
  <React.Fragment>
    <Year>2022 - January</Year>
    <Logo>
      <img
        src={trustpilot}
        alt="Trustpilot: 5 stars"
        css={css`
          height: 24px;
        `}
      />
    </Logo>
    <Headline>We get our 7,000th Trustpilot review.</Headline>
    <ReadMoreLink
      text="Read more customer stories"
      href="https://uk.trustpilot.com/review/habito.com"
      width="content-width"
    />
  </React.Fragment>
)
const twentytwo = (
  <React.Fragment>
    <Year>2022 - June</Year>
    <Headline>We’ve sorted more than £9 billion worth of mortgages.</Headline>
  </React.Fragment>
)
const twentythree = (
  <React.Fragment>
    <Year>2022 - December</Year>
    <Logo>
      <PlusLogo />
    </Logo>
    <Headline>Habito Plus passes 2,000 submissions.</Headline>
  </React.Fragment>
)
// Awaiting FCA approval...
// const twentyfour = (
//   <React.Fragment>
//     <Year>2023 - May</Year>
//     <Headline>
//       Ying Tan becomes significant shareholder and CEO of Habito.
//     </Headline>
//   </React.Fragment>
// )
const twentyfive = (
  <React.Fragment>
    <Year>2023 - May</Year>
    <Logo>
      <img
        src={trustpilot}
        alt="Trustpilot: 5 stars"
        css={css`
          height: 24px;
        `}
      />
    </Logo>
    <Headline>We get our 8,000th Trustpilot review.</Headline>
    <ReadMoreLink
      text="Read more customer stories"
      href="https://uk.trustpilot.com/review/habito.com"
      width="content-width"
    />
  </React.Fragment>
)
const twentysix = (
  <React.Fragment>
    <Year>2023 - May</Year>
    <Headline>
      Winner of British Banking Awards: Treating Customers Fairly
      <span
        css={css`
          margin-top: -${vertical.xxs};
        `}
      >
        <PartyIcon width={iconSize.l} height={iconSize.l} />
      </span>
    </Headline>
  </React.Fragment>
)

interface JourneyItem {
  card: React.ReactElement
  year?: YearType
}

// only the first card of each year gets the `year` value
// for display purposes
const journeyItems: JourneyItem[] = [
  { card: one, year: 2014 },
  { card: two, year: 2015 },
  { card: three },
  { card: four, year: 2016 },
  { card: five },
  { card: six, year: 2017 },
  { card: seven },
  { card: eight },
  { card: nine },
  { card: ten, year: 2018 },
  { card: eleven },
  { card: twelve, year: 2019 },
  { card: thirteen },
  { card: fourteen },
  { card: fifteen, year: 2020 },
  { card: sixteen },
  { card: seventeen },
  { card: eighteen },
  { card: nineteen, year: 2021 },
  { card: twenty, year: 2022 },
  { card: twentyone },
  { card: twentytwo },
  { card: twentythree },
  // { card: twentyfour, year: 2023 }, (awaiting FCA approval)
  { card: twentyfive, year: 2023 },
  { card: twentysix },
]

export default journeyItems
