import { VFC } from "react"
import styled from "@emotion/styled"
import {
  ArrowDownThickIcon,
  ArrowLeftThickIcon,
  ArrowRightThickIcon,
  ArrowUpThickIcon,
} from "./icons/Icons"
import { colours } from ".."
import { resets } from "../resets"
import { mixins } from "../mixins"

type Size = "small" | "large"
type ArrowColour = "black" | "white"

const Circle = styled.button<{
  arrowColour: ArrowColour
  filled: boolean
  size: Size
}>`
  ${resets.button}
  border-radius: 50%;
  border: 2px solid;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  flex: none;

  ${({ arrowColour, filled }) => {
    if (filled) {
      return `background-color: ${
        arrowColour === "black" ? colours.white : colours.offBlack
      };`
    } else {
      return `background-color: transparent;`
    }
  }}

  ${({ arrowColour, filled }) =>
    `
      border-color: ${
        arrowColour === "black"
          ? filled
            ? colours.white
            : colours.offBlack
          : filled
            ? "transparent"
            : colours.white
      };
      svg {
        color: ${arrowColour === "black" ? colours.offBlack : colours.white};
      }  
    `}
  ${props =>
    props.size === "large"
      ? `
    width: 56px;
    height: 56px;
  `
      : `
    width: 40px;
    height: 40px;
  `}

  &:hover {
    ${props => `
      border-color: ${
        props.arrowColour === "black"
          ? props.filled
            ? colours.white
            : colours.offBlack
          : props.filled
            ? colours.offBlack
            : colours.white
      };
      background-color: ${
        props.arrowColour === "black" ? colours.offBlack : colours.white
      };
      svg {
        color: ${
          props.arrowColour === "black" ? colours.white : colours.offBlack
        };
      }
    `}
  }

  &:focus {
    ${mixins.focused}
  }

  &:disabled {
    pointer-events: none;
    opacity: 0.5;
  }
`

export const CircleArrow: VFC<{
  direction: "left" | "right" | "up" | "down"
  arrowColour: ArrowColour
  filled: boolean
  size: Size
  "aria-label": string
  onClick: () => void
  disabled?: boolean
  className?: string
}> = ({ direction, ...props }) => (
  <Circle {...props}>
    {direction === "down" ? (
      <ArrowDownThickIcon />
    ) : direction === "left" ? (
      <ArrowLeftThickIcon />
    ) : direction === "right" ? (
      <ArrowRightThickIcon />
    ) : (
      <ArrowUpThickIcon />
    )}
  </Circle>
)
